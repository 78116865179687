export const MESSAGES = {
    DESCRIPTION_DELIVERY: "Entrega en mi dirección",
    DESCRIPTION_PICKUP: "Pasaré recogiendo",
    PICKUP: "Pasa por tu pedido en la sucursal que seleccionaste, tu pedido estará listo cuando llegues.",
    DELIVERY: "Enviaremos tu pedido lo más pronto posible, debes estar pendiente de tu teléfono, nuestro repartidor de contactará.",
    DESCRIPTION_PAY_ONLINE: "Con tarjeta",
    DESCRIPTION_PAY_WITH_CASH: "En efectivo",
    DESCRIPTION_PAY_WITH_CARD: "Pago en persona",
    DESCRIPTION_PAY_TRANSFER: "Bancaria",
    PAY_ONLINE: "Paga con tu tarjeta de crédito o débito de forma segura, no almacenamos ningún dato de tu tarjeta.",
    PAY_WITH_CASH: "Paga en efectivo cuando recibas de pedido ya sea con delivery o pickup.",
    PAY_WITH_CARD: "Paga con tu tarjeta de crédito o débito de forma presencial cuando recibas tu pedido.",
    PAY_TRANSFER: "Antes de procesar la orden has un depósito a la cuenta <strong>DESCRIPTION</strong> y envía el comprobante al número <strong>PHONE</strong>.",
    TRANSACTION_SECURITY_NOTICE: "Tu transacción es segura, no almacenamos ningún dato de tu tarjeta, al darle click al botón aceptas los",
    TERMS_AND_CONDITIONS: "Términos y Condiciones de Servicio",
    TRANSACTION_SECURITY_NOTICE_OTHERS_PAYMENTS: "Tu transacción es segura, al darle click al botón aceptas los",
};
